import { Component, Input } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { NgClass } from '@angular/common';
import { assetUrl } from '../../utils/asset-url';

/**
 * to change @Input to input or model see the documentation below (actually in preview)
 * @see https://angular.io/guide/model-inputs#differences-between-model-and-input
 * @see https://angular.io/guide/signal-inputs
 * @see https://github.com/ngx-lottie/ngx-lottie
 */
@Component({
  selector: 'app-lottie-animation',
  templateUrl: './lottie-animation.component.html',
  styleUrls: ['./lottie-animation.component.scss'],
  standalone: true,
  imports: [LottieComponent, NgClass],
})
export class LottieAnimationComponent {
  @Input() set animation(fileName: string) {
    this.options = {
      path: assetUrl(`/animations/${fileName}`),
    };
  }

  @Input() text!: string;
  @Input() sizePx!: string;
  @Input() fullHeightContainer = false;
  @Input() offsetPx = '0px';

  public options!: AnimationOptions;
}
