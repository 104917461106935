import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import * as countries from 'i18n-iso-countries';
import frCountries from 'i18n-iso-countries/langs/fr.json';

// Sadly, i18n-iso-countries does not support dynamic imports, so we have to manually import all the languages we want to support
const langToCountryImport: Map<string, countries.LocaleData> = new Map<string, countries.LocaleData>([
  [frCountries.locale, frCountries],
]);

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  constructor(private translocoService: TranslocoService) {
    this.translocoService.getAvailableLangs().forEach((lang) => this.registerLocale(lang as string));
  }

  iso3toCountryName(iso3: string): string {
    const countryName = countries.getName(iso3, this.translocoService.getActiveLang());
    if (countryName === undefined) {
      throw new Error(`Country with ISO3 code "${iso3}" not found`);
    }

    return countryName;
  }

  private registerLocale(lang: string): void {
    const countriesLang = langToCountryImport.get(lang);
    if (countriesLang === undefined) {
      throw new Error(`Country language "${lang}" not found`);
    }

    return countries.registerLocale(countriesLang);
  }
}
