<div class="container" onepLayout *transloco="let t; read: 'payment.paymentSuccess'">
  <div onepColumn [mobile]="0" [tablet]="1" [desktop]="2"></div>
  <div onepColumn [mobile]="2" [tablet]="6" [desktop]="8">
    <app-lottie-animation
      animation="animated_thumb.json"
      sizePx="204px"
      offsetPx="-60px"
    ></app-lottie-animation>
    <h1>{{ t('title') }}</h1>
    <p [innerHTML]="t('thanks', { orderId: orderId() })"></p>
    <ng-container *transloco="let t; read: 'glady'">
      <p [innerHTML]="t('address')"></p>
      <button
        onepButton
        [onepButtonType]="'primary'"
        [loading]="false"
        [size]="'normal'"
        (click)="onBackToManagerClick()"
      >
        {{ t('home') }}
      </button>
    </ng-container>
  </div>
  <div onepColumn [mobile]="0" [tablet]="1" [desktop]="2"></div>
</div>
