export const environment = {
  name: 'integration',
  production: false,
  payment: {
    stripe: {
      publicKey:
        'pk_test_51Kw0Y8EzkXza18HrpFZFtkmcDhdUEzTmiiI2v3KjTOnsIyLXVRhlBjjVRiLemjfWWQEiqOlOnCg3MLPVGgGO5OWA00AQqsJuA1',
    },
  },
  url: {
    graphQL: 'https://onep-gateway.integration.onep.wedoogift.com/graphql',
    glady: {
      home: 'https://home-page.demo.tech.glady.com', // Pas d'environnement d'intégration pour site vitrine
      manager: 'https://manager.integration.glady.com',
    },
    payment: {
      success: 'https://pay.integration.glady.com/payment-success',
    },
  },
  email: {
    sav_order: 'sav.commande@glady.com',
    manager: 'manager@glady.com',
  },
};
