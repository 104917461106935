import { SharedModule } from '../../shared/shared.module';
import { PaymentMethodSelectorComponent } from './payment-method-selector/payment-method-selector.component';
import { BillingOrder, PaymentMethodType } from '../../../generated/types';
import { OrderService } from '../../services/order.service';
import { NgOptimizedImage } from '@angular/common';
import { BillingAddressComponent } from './billing-address/billing-address.component';
import { ValidatePaymentComponent } from './validate-payment/validate-payment.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrderSummaryCardComponent } from './order-summary-card/order-summary-card.component';
import { OrderTotalCardComponent } from './order-total-card/order-total-card.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
import { GladyTrackingLogCustomEvent, TrackingService } from '@onep/front-common-lib/tracking';
import { HeaderNavbarComponent } from './header-navbar/header-navbar.component';

@Component({
  selector: 'app-payment',
  standalone: true,
  styleUrls: ['./payment.component.scss'],
  templateUrl: './payment.component.html',
  imports: [
    SharedModule,
    PaymentMethodSelectorComponent,
    NgOptimizedImage,
    BillingAddressComponent,
    ValidatePaymentComponent,
    OrderSummaryCardComponent,
    OrderTotalCardComponent,
    PaymentSummaryComponent,
    HeaderNavbarComponent,
  ],
})
export class PaymentComponent implements OnInit {
  @ViewChild(PaymentMethodSelectorComponent) paymentMethodSelectorComponent!: PaymentMethodSelectorComponent;

  @Input() token!: string;

  order?: BillingOrder;
  paymentMethodSelected?: PaymentMethodType;

  constructor(
    private orderService: OrderService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.orderService.getOrder(this.token).subscribe((order) => {
      this.trackingService.extraAttributes = { product_sku: order.productSkuType };
      this.trackingService.sendEvent(new GladyTrackingLogCustomEvent('ecommerce', 'checkoutStepStarted', []));
      return (this.order = order);
    });
  }

  onPaymentMethodSelectedChange(paymentMethodType: PaymentMethodType | undefined): void {
    this.paymentMethodSelected = paymentMethodType;
  }

  isPaymentMethodSelected(): boolean {
    return this.paymentMethodSelected !== undefined;
  }

  getSuccessUrl(): string | undefined {
    if (this.order?.successUrl === null) {
      return undefined;
    }
    return this.order?.successUrl;
  }

  onPaymentClick(successUrl: string): void {
    this.trackingService.sendEvent(new GladyTrackingLogCustomEvent('checkout', 'checkoutStep1', []));
    this.paymentMethodSelectorComponent.onPaymentClick(successUrl);
  }

  onPromoCodeUpdated() {
    this.orderService.getOrder(this.token).subscribe((order) => {
      this.order = undefined;
      setTimeout(() => {
        this.order = order;
        this.paymentMethodSelectorComponent.refreshSelectedPaymentMethod();
      }, 0);
    });
  }
}
