import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { BillingOrder, BillingOrderItem, BusinessVolumeType, CheckType, Maybe } from '../../../../generated/types';
import { SharedModule } from '../../../shared/shared.module';
import { ModalInput, ModalModule, ONEP_MODAL_INPUT } from '@onep/ng-uikit-lib/ui/modal';
import { DistributionSummaryModule } from '@onep/ng-uikit-lib/ui/distribution-summary';
import { GridModule } from '@onep/ng-uikit-lib/grid';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-order-details',
  standalone: true,
  templateUrl: './order-details-modal.component.html',
  styleUrl: './order-details-modal.component.scss',
  imports: [SharedModule, GridModule, DistributionSummaryModule, ModalModule, ButtonModule],
})
export class OrderDetailsModalComponent implements OnInit {
  order!: BillingOrder;
  billingOrderGifts?: BillingOrderItem[];
  billingOrderCultures?: BillingOrderItem[];
  billingOrderOptions?: BillingOrderItem[];

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler() {
    this.closeModal();
  }

  constructor(
    @Inject(ONEP_MODAL_INPUT) private modalInput: ModalInput,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.order = this.modalInput.input.order;

    this.billingOrderGifts = this.order.items.filter((o) => o.businessVolumeType === BusinessVolumeType.Gift);
    this.billingOrderCultures = this.order.items.filter((o) => o.businessVolumeType === BusinessVolumeType.Culture);
    this.billingOrderOptions = this.order.items.filter(
      (o) => o.businessVolumeType === BusinessVolumeType.NotApplicable,
    );
  }

  closeModal(): void {
    this.modalInput.close({});
  }

  getCheckType(checkType: Maybe<CheckType> | undefined): string {
    const translationPrefix = 'order.checkTypes.';

    switch (checkType) {
      case CheckType.Liberty:
      case CheckType.Culture:
      case CheckType.BackToSchool:
      case CheckType.Happiness:
        return this.translocoService.translate(translationPrefix + checkType) + ' - ';
      default:
        return '';
    }
  }

  hasDiscount(item: BillingOrderItem): boolean {
    const relatedDiscounts = this.order.discounts?.filter(
      (discount) => discount.transactionalCode === item.transactionalCode,
    );
    return relatedDiscounts !== undefined && relatedDiscounts!.length > 0;
  }

  getDiscountedLabel(item: BillingOrderItem): string {
    const relatedDiscounts = this.order.discounts?.filter(
      (discount) => discount.transactionalCode === item.transactionalCode,
    );

    let label = '';

    if (relatedDiscounts !== undefined && relatedDiscounts!.length > 0) {
      if (relatedDiscounts[0].discountWithoutTax >= item.totalAmount) {
        label = 'Offert';
      } else {
        label = item.totalAmount - relatedDiscounts[0].discountWithoutTax + ' €';
      }
    }

    return label;
  }

  getDueAmount() {
    return this.order.amount.excludingTaxDiscountAmount
      ? this.order.amount.excludingTaxAmount - this.order.amount.excludingTaxDiscountAmount!
      : this.order.amount.excludingTaxAmount;
  }
}
