<ng-container *transloco="let t; read: 'payment'">
  <div class="logo">
    <img [src]="getLogoAssetUrl()" alt="glady" />
  </div>
  <div class="content">
    <p class="cgv onep-text-caption-1" [innerHTML]="t('cgv', { homeUrl: getHomeUrl() })"></p>
    <button
      class="payment-button"
      onepButton
      [onepButtonType]="'primary'"
      [disabled]="!paymentMethodType()"
      [loading]="false"
      [size]="'normal'"
      (click)="onValidatePaymentClick()"
    >
      {{ t('validate') }}
    </button>
  </div>
</ng-container>
