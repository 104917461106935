<img
  ngSrc="assets/logos/logo-glady-full-red-pluxee-main.svg"
  height="53"
  width="124"
  aria-hidden="true"
  alt="glady-logo"
  priority="true"
/>
<div class="container" onepLayout *transloco="let t; read: 'error'">
  <div onepColumn [mobile]="0" [tablet]="1" [desktop]="2"></div>
  <div onepColumn [mobile]="2" [tablet]="6" [desktop]="8">
    <h1>{{ t('title') }}</h1>
    <p>{{ t('message1') }}</p>
    <p>{{ t('message2') }}</p>
    <button
      onepButton
      [onepButtonType]="'primary'"
      [loading]="false"
      [size]="'normal'"
      (click)="onCtaClick()"
    >
      {{ t('cta') }}
    </button>
  </div>
  <div onepColumn [mobile]="0" [tablet]="1" [desktop]="2"></div>
</div>
