import { Component, input, OnInit } from '@angular/core';
import { BillingOrder } from '../../../../generated/types';
import { TranslocoCurrencyPipe } from '@jsverse/transloco-locale';
import { TranslocoDirective } from '@jsverse/transloco';
import { IconModule } from '@onep/ng-uikit-lib/icon';
import { ModalService, ModalType } from '@onep/ng-uikit-lib/ui/modal';
import { NeedHelpModalComponent } from '../../modals/need-help-modal/need-help-modal.component';
import { OverlayService } from '@onep/ng-uikit-lib/overlay';

@Component({
  selector: 'app-order-total-card',
  standalone: true,
  imports: [TranslocoCurrencyPipe, TranslocoDirective, IconModule],
  providers: [ModalService, OverlayService],
  templateUrl: './order-total-card.component.html',
  styleUrl: './order-total-card.component.scss',
})
export class OrderTotalCardComponent implements OnInit {
  order = input.required<BillingOrder>();
  dueAmount: number = 0;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.dueAmount = this.order().amount.discountAmount
      ? this.order().amount.includingTaxAmount - this.order().amount.discountAmount!
      : this.order().amount.includingTaxAmount;
  }

  openNeedHelpModal() {
    this.modalService.show({
      type: ModalType.PopInModal,
      component: NeedHelpModalComponent,
      input: { productSkuType: this.order().productSkuType },
    });
  }
}
