import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslocoService } from '@jsverse/transloco';
import { SharedModule } from './shared/shared.module';
import { TrackingService } from '@onep/front-common-lib/tracking';
import { environment } from '../environments/environment';
import { ComponentCanDeactivate } from './guards/can-leave.guard';
import { Observable } from 'rxjs';
import { PaymentPageStatus } from './models/enums/payment-page-status';
import { PaymentService } from './services/payment.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [SharedModule, RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, ComponentCanDeactivate {
  currentPageStatus?: PaymentPageStatus;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly paymentService: PaymentService,
    private readonly translocoService: TranslocoService,
    private readonly trackingService: TrackingService,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.initFavicon();
    this.initDidomi();
    this.document.documentElement.lang = this.translocoService.getActiveLang();
    this.trackingService.initRouterTracking();
    this.paymentService.getCurrentPaymentStatus().subscribe((value) => (this.currentPageStatus = value));
  }

  initFavicon(): void {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    const faviconRootPath = '/assets/favicon/';

    if (favicon) {
      const faviconPath = !['loadtest', 'local'].includes(environment.name)
        ? `favicon.${environment.name}.svg`
        : 'favicon.production.svg';

      favicon.href = faviconRootPath + faviconPath;
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if payment is not succeeded here;
    // returning true will navigate to returnUrl without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.currentPageStatus !== undefined && this.currentPageStatus === PaymentPageStatus.SUCCESS;
  }

  confirmationMessage(): string {
    return this.translocoService.translate('quitConfirmation');
  }

  private initDidomi(): void {
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.text = `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
    else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
      var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
      if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
      var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("b91afb15-bbb5-41ef-84a8-7d2a8d38def8")})();`;
    this.renderer.appendChild(this.document.body, script);
  }
}
