import { Injectable } from '@angular/core';
import { ValidatePaymentGQL, ValidatePaymentInput, ValidatePaymentResponse } from '../../generated/types';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { PaymentPageStatus } from '../models/enums/payment-page-status';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly paymentProcessStatusBehaviorSubject: BehaviorSubject<PaymentPageStatus> =
    new BehaviorSubject<PaymentPageStatus>(PaymentPageStatus.NOT_STARTED);

  constructor(private validatePaymentGQL: ValidatePaymentGQL) {}

  validatePayment(input: ValidatePaymentInput): Observable<ValidatePaymentResponse> {
    return this.validatePaymentGQL
      .mutate({ input })
      .pipe(map((validatePaymentMutation) => validatePaymentMutation.data!.validatePayment));
  }

  getCurrentPaymentStatus(): Observable<PaymentPageStatus> {
    return this.paymentProcessStatusBehaviorSubject.asObservable();
  }

  updateCurrentPaymentStatus(value: PaymentPageStatus): void {
    this.paymentProcessStatusBehaviorSubject.next(value);
  }
}
