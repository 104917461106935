import { Component, input, OnInit, output } from '@angular/core';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { FormFieldsModule } from '@onep/ng-uikit-lib/form-fields';
import { FormsModule } from '@angular/forms';
import { PromoCodeService } from '../../../services/promo-code.service';
import { BillingOrder, RemovePromoCodeResponse, SubmitPromoCodeResponse } from '../../../../generated/types';
import { NgOptimizedImage } from '@angular/common';
import { Maybe } from 'graphql/jsutils/Maybe';

@Component({
  selector: 'app-promo-code-card',
  standalone: true,
  imports: [ButtonModule, TranslocoDirective, FormFieldsModule, FormsModule, NgOptimizedImage],
  templateUrl: './promo-code-card.component.html',
  styleUrl: './promo-code-card.component.scss',
})
export class PromoCodeCardComponent implements OnInit {
  token = input.required<string>();
  order = input.required<BillingOrder>();

  promoCodeUpdated = output<void>();

  buttonLabel: 'apply' | 'delete' = 'apply';
  inputText = '';
  currentValidPromoCode?: string;
  isLoading: boolean = false;
  translationPrefix = 'order.summary.promo.';
  errorMessage = '';

  constructor(
    private promoCodeService: PromoCodeService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    if (this.order().promoCode && this.order().promoCode !== null) {
      this.populatePromoCode();
    }
  }

  private populatePromoCode() {
    this.currentValidPromoCode = this.order().promoCode!;
    this.inputText = this.currentValidPromoCode;
    this.buttonLabel = 'delete';
  }

  private unpopulatePromoCode() {
    this.currentValidPromoCode = undefined;
    this.inputText = '';
    this.buttonLabel = 'apply';
  }

  onButtonClicked() {
    this.errorMessage = '';

    if (this.currentValidPromoCode !== undefined) {
      this.onRemovePromoCodeClicked();
    } else {
      this.onApplyPromoCodeClicked();
    }
  }

  onApplyPromoCodeClicked() {
    if (!this.isLoading) {
      this.isLoading = true;

      this.promoCodeService
        .submitPromoCode({ token: this.token(), promoCode: this.inputText })
        .subscribe((response: SubmitPromoCodeResponse) => {
          if (response.validPromoCode) {
            this.populatePromoCode();
            this.promoCodeUpdated.emit();
          } else {
            this.displayPromoCodeError(response.invalidPromoCodeReason, this.inputText);
          }
          this.isLoading = false;
        });
    }
  }

  onRemovePromoCodeClicked() {
    if (!this.isLoading) {
      this.isLoading = true;

      this.promoCodeService.removePromoCode({ token: this.token() }).subscribe((response: RemovePromoCodeResponse) => {
        if (response.success) {
          this.unpopulatePromoCode();
          this.promoCodeUpdated.emit();
        } else {
          this.errorMessage = this.translocoService.translate(this.translationPrefix + 'remove_promo_code_error');
        }
        this.isLoading = false;
      });
    }
  }

  isInputFieldEditable() {
    return !this.isLoading && this.currentValidPromoCode !== undefined;
  }

  private displayPromoCodeError(invalidPromoCodeReason: Maybe<string> | undefined, inputText: string) {
    const keyParameters = {
      promoCode: this.inputText,
    };

    if (
      invalidPromoCodeReason === 'promo_code_not_found' ||
      invalidPromoCodeReason === 'promotion_code_not_active' ||
      invalidPromoCodeReason === 'invalid_product_rate_plans' ||
      invalidPromoCodeReason === 'unable_to_validate_promo_code'
    ) {
      this.errorMessage = this.translocoService.translate(
        this.translationPrefix + invalidPromoCodeReason,
        keyParameters,
      );
    } else {
      this.errorMessage = this.translocoService.translate(this.translationPrefix + 'unknown_error', keyParameters);
    }
  }
}
