import { Component, Inject, OnInit } from '@angular/core';
import { ModalInput, ModalModule, ONEP_MODAL_INPUT, State, StateIcon } from '@onep/ng-uikit-lib/ui/modal';
import { GridModule } from '@onep/ng-uikit-lib/grid';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { SpinnerModule } from '@onep/ng-uikit-lib/ui/spinner';
import { SharedModule } from '../../../shared/shared.module';
import { PaymentProcessStatus } from '../../../models/enums/payment-process-status';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-payment-process-modal',
  standalone: true,
  imports: [ModalModule, GridModule, SharedModule, TranslocoDirective, SpinnerModule, NgClass],
  templateUrl: './payment-process-modal.component.html',
  styleUrl: './payment-process-modal.component.scss',
})
export class PaymentProcessModalComponent implements OnInit {
  currentState!: State;
  processingState!: State;
  errorState!: State;
  successState!: State;

  currentStateClass: 'processing' | 'error' | 'success' = 'processing';

  private readonly processingKey = 'payment.paymentProcessModal.processing';
  private readonly errorKey = 'payment.paymentProcessModal.error';
  private readonly successKey = 'payment.paymentProcessModal.success';
  private readonly titleKey = 'title';
  private readonly commandTitleKey = 'titleCommand';
  private readonly textKey = 'text';
  private readonly commandTextKey = 'textCommand';
  private readonly buttonLabelKey = 'buttonLabel';

  constructor(
    @Inject(ONEP_MODAL_INPUT)
    private modalInput: ModalInput,
    private translocoService: TranslocoService,
  ) {
    this.initializeStates(this.modalInput.input.isAsync$);
  }

  ngOnInit() {
    this.modalInput.input.status$.subscribe((status: PaymentProcessStatus) => {
      switch (status) {
        case PaymentProcessStatus.PROCESSING:
          this.currentStateClass = 'processing';
          this.currentState = this.processingState;
          break;
        case PaymentProcessStatus.SUCCESS:
          this.currentStateClass = 'success';
          this.currentState = this.successState;
          break;
        case PaymentProcessStatus.ERROR:
          this.currentStateClass = 'error';
          this.currentState = this.errorState;
          break;
      }
    });
  }

  onClick(): void {
    if (this.currentState == this.errorState) {
      this.modalInput.close({});
    }
  }

  private translateState(
    titleKey: string,
    contentKey: string,
    buttonLabelKey: string | undefined,
    isLoading: boolean,
    icon: StateIcon,
  ): State {
    return {
      title: this.translocoService.translate(titleKey),
      content: this.translocoService.translate(contentKey),
      buttonLabel: buttonLabelKey ? this.translocoService.translate(buttonLabelKey) : '',
      isLoading: isLoading,
      icon: icon,
    };
  }

  initializeStates(isAsync: boolean): void {
    this.processingState = this.translateState(
      `${this.processingKey}.${isAsync ? this.commandTitleKey : this.titleKey}`,
      `${this.processingKey}.${this.textKey}`,
      undefined,
      true,
      StateIcon.LOADING,
    );
    this.errorState = this.translateState(
      `${this.errorKey}.${isAsync ? this.commandTitleKey : this.titleKey}`,
      `${this.errorKey}.${isAsync ? this.commandTextKey : this.textKey}`,
      `${this.errorKey}.${this.buttonLabelKey}`,
      false,
      StateIcon.ERROR,
    );
    this.successState = this.translateState(
      `${this.successKey}.${isAsync ? this.commandTitleKey : this.titleKey}`,
      `${this.successKey}.${this.textKey}`,
      undefined,
      false,
      StateIcon.SUCCESS,
    );
  }
}
