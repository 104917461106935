import { Component, input } from '@angular/core';
import { Location } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderModule } from '@onep/ng-uikit-lib/ui/header';
import { FieldDisplayModule } from '@onep/ng-uikit-lib/ui/field-display';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { WindowService } from '@onep/front-common-lib/window';

@Component({
  selector: 'app-header-navbar',
  standalone: true,
  imports: [SharedModule, HeaderModule, FieldDisplayModule, ButtonModule],
  templateUrl: './header-navbar.component.html',
  styleUrl: './header-navbar.component.scss',
})
export class HeaderNavbarComponent {
  journeyUrl = input<string | null>();
  journeyName = input.required<string>();

  constructor(
    private windowService: WindowService,
    private location: Location,
  ) {}

  goToJourney() {
    if (this.journeyUrl()) {
      this.windowService.redirect(this.journeyUrl() as string);
    } else {
      this.location.back();
    }
  }
}
