<div
  class="loader-container"
  [ngClass]="{ 'full-height': fullHeightContainer }"
  [style]="'margin-left:' + offsetPx"
>
  <ng-lottie [height]="sizePx" [width]="sizePx" [options]="options"></ng-lottie>
  @if (text) {
    <div class="onep-text-heading-4 bold" aria-hidden="true">{{ text }}</div>
  }
</div>
