import { Component, input } from '@angular/core';
import { OptionCardModule } from '@onep/ng-uikit-lib/ui/option-card';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { WindowService } from '@onep/front-common-lib/window';
import { GridModule } from '@onep/ng-uikit-lib/grid';
import { LottieAnimationComponent } from '../../lottie-animation/lottie-animation.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-payment-success',
  standalone: true,
  imports: [OptionCardModule, TranslocoDirective, ButtonModule, GridModule, LottieAnimationComponent],
  templateUrl: './payment-success.component.html',
  styleUrl: './payment-success.component.scss',
})
export class PaymentSuccessComponent {
  orderId = input.required<string>();

  managerHomeUrl = environment.url.glady.manager;

  constructor(private windowService: WindowService) {}

  onBackToManagerClick() {
    this.windowService.redirect(this.managerHomeUrl);
  }
}
