import { Routes } from '@angular/router';
import { tokenGuard } from './guards/token.guard';
import { ErrorComponent } from './components/error/error.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentSuccessComponent } from './components/payment/payment-success/payment-success.component';
import { CanLeaveGuard } from './guards/can-leave.guard';

export const routes: Routes = [
  {
    path: '',
    component: PaymentComponent,
    canActivate: [tokenGuard],
    canDeactivate: [CanLeaveGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
