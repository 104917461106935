<ng-container *transloco="let t; read: 'payment.method'">
  <h2>{{ t('select') }}</h2>
  <div>
    @if (loaded) {
      <form [formGroup]="formGroup">
        @for (paymentMethod of order()?.paymentMethods; track paymentMethod.type) {
          <onep-option-card
            [disabled]="!isEnabled(paymentMethod)"
            [toggled]="selectedPaymentMethodType === paymentMethod.type"
            (click)="onPaymentMethodClick(paymentMethod.type)"
            onKeyPress=""
            onKeyDown=""
            onKeyUp=""
          >
            <onep-radio-button
              value="{{ paymentMethod.type }}"
              [(ngModel)]="selectedPaymentMethodType"
              formControlName="selectedPaymentMethod"
              title="{{ getTitle(paymentMethod) }}"
              subtitle=" "
              (click)="onPaymentMethodClick(paymentMethod.type)"
              onKeyPress=""
              onKeyDown=""
              onKeyUp=""
              class="payment-method-radio-button"
            />
            <onep-option-card-content>{{ getDescription(paymentMethod) }}</onep-option-card-content>
            <onep-option-card-conditions>{{
              getExtraDescription(paymentMethod)
            }}</onep-option-card-conditions>

            @if (
              paymentMethod.type === cardPaymentMethod &&
              paymentMethod.type === selectedPaymentMethodType
            ) {
              <app-stripe-credit-card
                class="stripe-credit-card"
                [token]="token()"
              ></app-stripe-credit-card>
            }
          </onep-option-card>
        }
      </form>
    } @else {
      <onep-spinner></onep-spinner>
    }
  </div>
</ng-container>
