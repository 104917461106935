import { Component, input } from '@angular/core';
import { BillingAddress } from '../../../../generated/types';
import { SharedModule } from '../../../shared/shared.module';
import { OptionCardModule } from '@onep/ng-uikit-lib/ui/option-card';
import { CountryService } from '../../../services/country.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-billing-address',
  standalone: true,
  imports: [SharedModule, OptionCardModule],
  templateUrl: './billing-address.component.html',
  styleUrl: './billing-address.component.scss',
})
export class BillingAddressComponent {
  constructor(protected countryService: CountryService) {}

  address = input.required<BillingAddress>();

  getAddressLine2(): string {
    return this.address().addressLine2 ? this.address().addressLine2 + ', ' : '';
  }

  getCountryName(): string {
    return this.countryService.iso3toCountryName(this.address().countryIsoCode);
  }

  getManagerUrl(): string {
    return environment.url.glady.manager;
  }
}
