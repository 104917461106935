import { NgModule } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { ResponsiveService } from '@onep/ng-uikit-lib/grid';

@NgModule({
  imports: [TranslocoDirective, TranslocoLocaleModule],
  providers: [ResponsiveService],
  exports: [TranslocoDirective, TranslocoLocaleModule],
})
export class SharedModule {}
