<ng-container *transloco="let t">
  @if (elementsOptions.clientSecret) {
    <div class="credit-card">
      <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
        <ngx-stripe-payment [options]="paymentElementOptions" />
      </ngx-stripe-elements>
    </div>
  } @else {
    <onep-spinner></onep-spinner>
  }
</ng-container>
