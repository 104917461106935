import { Component, Inject, OnInit } from '@angular/core';
import { ModalInput, ModalModule, ONEP_MODAL_INPUT } from '@onep/ng-uikit-lib/ui/modal';
import { ProductSkuType } from '../../../../generated/types';
import { TranslocoDirective } from '@jsverse/transloco';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-need-help-modal',
  standalone: true,
  imports: [ModalModule, TranslocoDirective],
  templateUrl: './need-help-modal.component.html',
  styleUrl: './need-help-modal.component.scss',
})
export class NeedHelpModalComponent implements OnInit {
  email!: string;
  modalContentKey!: string;

  constructor(@Inject(ONEP_MODAL_INPUT) private modalInput: ModalInput) {}

  ngOnInit(): void {
    this.email =
      this.modalInput.input.productSkuType === ProductSkuType.GiftPaper
        ? environment.email.sav_order
        : environment.email.manager;
    this.modalContentKey =
      this.modalInput.input.productSkuType === ProductSkuType.DepositDistrib ? 'text' : 'textWithFaq';
  }
}
