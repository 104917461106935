import { Component, input } from '@angular/core';
import { BillingOrder } from '../../../../generated/types';
import { SharedModule } from '../../../shared/shared.module';
import { ModalService, ModalType } from '@onep/ng-uikit-lib/ui/modal';
import { OrderDetailsModalComponent } from '../../modals/order-details-modal/order-details-modal.component';
import { IconModule } from '@onep/ng-uikit-lib/icon';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { OverlayService } from '@onep/ng-uikit-lib/overlay';
import { ResponsiveService } from '@onep/ng-uikit-lib/grid';
import { GladyTrackingLogGenericEvent, TrackingService } from '@onep/front-common-lib/tracking';

@Component({
  selector: 'app-order-details-open-button',
  standalone: true,
  imports: [SharedModule, ButtonModule, IconModule],
  providers: [ModalService, OverlayService, ResponsiveService],
  templateUrl: './order-details-open-button.component.html',
  styleUrl: './order-details-open-button.component.scss',
})
export class OrderDetailsOpenButtonComponent {
  order = input.required<BillingOrder>();

  constructor(
    private modalService: ModalService,
    private trackingService: TrackingService,
  ) {}

  openOrderDetailsModal(): void {
    this.trackingService.sendEvent(new GladyTrackingLogGenericEvent('orderDetailClicked'));

    this.modalService.show({
      type: ModalType.MaxiModal,
      component: OrderDetailsModalComponent,
      input: { order: this.order() },
    });
  }
}
