<onep-pop-in-modal-title *transloco="let t; read: 'order.summary.help'">{{
  t('title')
}}</onep-pop-in-modal-title>
<onep-pop-in-modal-content
  *transloco="let t; read: 'order.summary.help'"
  [innerHTML]="t(modalContentKey, { email: email })"
></onep-pop-in-modal-content>
<onep-pop-in-modal-content
  ><span class="asterisk-text-details" *transloco="let t; read: 'order.summary.help'">{{
    t('asterisk')
  }}</span></onep-pop-in-modal-content
>
