<div class="content">
  <div class="total" *transloco="let t; read: 'order.summary.amount'">
    <div class="total-card flex-row">
      <div class="onep-text-heading-4 grey-light-black">{{ t('includingTax') }}</div>
      <div class="onep-text-heading-4 ml-auto grey-light-black">
        {{ dueAmount | translocoCurrency }}
      </div>
    </div>
    <div
      class="need-help onep-text-body-3 bold grey-light-black"
      (click)="openNeedHelpModal()"
      *transloco="let t; read: 'order.summary.help'"
      onKeyPress=""
      onKeyDown=""
      onKeyUp=""
    >
      <svg [onepIcon]="'question_circle'" color="black"></svg>
      {{ t('label') }}
    </div>
  </div>
</div>
