import { Injectable } from '@angular/core';
import {
  RemovePromoCodeGQL,
  RemovePromoCodeInput,
  RemovePromoCodeResponse,
  SubmitPromoCodeGQL,
  SubmitPromoCodeInput,
  SubmitPromoCodeResponse,
} from '../../generated/types';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService {
  constructor(
    private submitPromoCodeGQL: SubmitPromoCodeGQL,
    private removePromoCodeGQL: RemovePromoCodeGQL,
  ) {}

  submitPromoCode(input: SubmitPromoCodeInput): Observable<SubmitPromoCodeResponse> {
    return this.submitPromoCodeGQL
      .mutate({ input })
      .pipe(map((submitPromoCodeMutation) => submitPromoCodeMutation.data!.submitPromoCode));
  }

  removePromoCode(input: RemovePromoCodeInput): Observable<RemovePromoCodeResponse> {
    return this.removePromoCodeGQL
      .mutate({ input })
      .pipe(map((removePromoCodeMutation) => removePromoCodeMutation.data!.removePromoCode));
  }
}
