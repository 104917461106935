import { Component } from '@angular/core';
import { WindowService } from '@onep/front-common-lib/window';
import { SharedModule } from '../../shared/shared.module';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { environment } from '../../../environments/environment';
import { GridModule } from '@onep/ng-uikit-lib/grid';
import { NgOptimizedImage } from '@angular/common';
import { GladyTrackingLogGenericEvent, TrackingService } from '@onep/front-common-lib/tracking';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [SharedModule, ButtonModule, GridModule, NgOptimizedImage],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent {
  constructor(
    private windowService: WindowService,
    private trackingService: TrackingService,
  ) {
    this.trackingService.sendEvent(new GladyTrackingLogGenericEvent('errorPaymentViewed'));
  }

  onCtaClick() {
    this.windowService.redirect(environment.url.glady.manager);
  }
}
