import { Component, input, output } from '@angular/core';
import { BillingOrder } from '../../../../generated/types';
import { OrderDetailsOpenButtonComponent } from '../order-details-open-button/order-details-open-button.component';
import { OrderSummaryCardComponent } from '../order-summary-card/order-summary-card.component';
import { OrderTotalCardComponent } from '../order-total-card/order-total-card.component';
import { TranslocoCurrencyPipe } from '@jsverse/transloco-locale';
import { SharedModule } from '../../../shared/shared.module';
import { IconModule } from '@onep/ng-uikit-lib/icon';
import { TooltipModule } from '@onep/ng-uikit-lib/ui/tooltip';
import { PromoCodeCardComponent } from '../promo-code-card/promo-code-card.component';

@Component({
  selector: 'app-payment-summary',
  standalone: true,
  imports: [
    SharedModule,
    OrderDetailsOpenButtonComponent,
    OrderSummaryCardComponent,
    OrderTotalCardComponent,
    TranslocoCurrencyPipe,
    IconModule,
    TooltipModule,
    PromoCodeCardComponent,
  ],
  templateUrl: './payment-summary.component.html',
  styleUrl: './payment-summary.component.scss',
})
export class PaymentSummaryComponent {
  token = input.required<string>();
  order = input.required<BillingOrder>();

  promoCodeUpdated = output<void>();

  onPromoCodeUpdated() {
    this.promoCodeUpdated.emit();
  }

  getTaxAmount() {
    if (this.order().discounts && this.order().discounts!.length > 0 && this.order().amount.discountTaxAmount) {
      return this.order().amount.taxAmount - this.order().amount.discountTaxAmount!;
    }
    return this.order().amount.taxAmount;
  }

  getGrossAmount() {
    if (
      this.order().discounts &&
      this.order().discounts!.length > 0 &&
      this.order().amount.excludingTaxDiscountAmount
    ) {
      return this.order().amount.excludingTaxAmount - this.order().amount.excludingTaxDiscountAmount!;
    }
    return this.order().amount.excludingTaxAmount;
  }
}
