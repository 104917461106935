<onep-header
  [displayHeaderLine]="true"
  [stepper]="true"
  [stepperCurrentStep]="1"
  [stepperTotalSteps]="1"
  *transloco="let t; read: 'payment.header'"
>
  <onep-header-left (click)="goToJourney()" onKeyDown="">
    <button onepButton [onepButtonType]="'icon'" [iconName]="'caret_left'" [iconSize]="24"></button>
    <onep-field-display [data]="t('previousStep')"></onep-field-display>
  </onep-header-left>
  <onep-header-center>
    <onep-field-display [data]="journeyName()" [helper]="t('lastStep')"></onep-field-display>
  </onep-header-center>
  <onep-header-right></onep-header-right>
</onep-header>
