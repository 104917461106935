import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PaymentIntentSecretGQL } from '../../generated/types';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private paymentIntentSecretGQL: PaymentIntentSecretGQL) {}

  getPaymentIntentSecret(token: string): Observable<string> {
    return this.paymentIntentSecretGQL
      .fetch({ token }, { fetchPolicy: 'no-cache' })
      .pipe(map((v) => v.data.paymentIntentSecret.clientSecret));
  }
}
