import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { OrderService } from '../services/order.service';
import { lastValueFrom } from 'rxjs';

export const tokenGuard: CanActivateFn = async (route, state) => {
  const router = inject(Router);
  const orderService = inject(OrderService);

  let canActivate: boolean = route.queryParamMap.has('token');

  const token = route.queryParamMap.get('token') as string;

  await lastValueFrom(orderService.getOrderId(token)).catch(() => (canActivate = false));

  if (!canActivate) {
    router.navigateByUrl('error', { skipLocationChange: true });
  }

  return canActivate;
};
