import { Injectable } from '@angular/core';
import { BillingOrder, OrderGQL, OrderIdGQL } from '../../generated/types';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private orderGQL: OrderGQL,
    private orderIdGQL: OrderIdGQL,
  ) {}

  getOrder(token: string): Observable<BillingOrder> {
    return this.orderGQL.fetch({ token }, { fetchPolicy: 'no-cache' }).pipe(map((orderQuery) => orderQuery.data.order));
  }

  getOrderId(token: string): Observable<string> {
    return this.orderIdGQL
      .fetch({ token }, { fetchPolicy: 'no-cache' })
      .pipe(map((orderQuery) => orderQuery.data.order.orderId));
  }
}
