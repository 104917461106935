<ng-container *transloco="let t; read: 'payment.address.billing'">
  <h2>{{ t('title') }}</h2>
  <onep-option-card>
    <onep-option-card-title>{{ address().recipient }}</onep-option-card-title>
    <onep-option-card-content>
      {{ address().addressLine1 }},
      {{ getAddressLine2() }}
      {{ address().zipCode }}
      {{ address().city }},
      {{ getCountryName() }}
    </onep-option-card-content>
    @if (address().label) {
      <onep-option-card-conditions>{{ address().label }}</onep-option-card-conditions>
    }
  </onep-option-card>
  <p [innerHTML]="t('update', { managerUrl: getManagerUrl() })"></p>
</ng-container>
