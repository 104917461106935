<div
  class="mt-16 flex-row"
  style="align-items: stretch"
  *transloco="let t; read: 'order.summary.promo'"
>
  <div class="input-wrapper">
    <input
      class="promo-code-input"
      [(ngModel)]="inputText"
      [disabled]="isInputFieldEditable()"
      [placeholder]="t('title')"
    />

    @if (currentValidPromoCode) {
      <img
        ngSrc="assets/icons/checkmark_success.svg"
        class="checkmark-success-icon"
        height="24"
        width="24"
        alt="{{ t('checkmarkSuccessIcon') }}"
        aria-hidden="true"
      />
    }
  </div>

  <button
    class="payment-button"
    onepButton
    [disabled]="!inputText"
    [onepButtonType]="'secondary'"
    [style]="{ 'border-radius': '0 4px 4px 0' }"
    [loading]="isLoading"
    [size]="'small'"
    (click)="onButtonClicked()"
  >
    {{ t(buttonLabel) }}
  </button>
</div>
@if (currentValidPromoCode) {
  <div class="mt-8" style="font-size: 12px; color: #666666">
    {{ 'Les codes promo sont non cumulables' }}
  </div>
}
@if (errorMessage) {
  <div class="mt-8 error-message-container">
    <img
      style="margin-right: 10px"
      ngSrc="assets/icons/warning.svg"
      height="24"
      width="24"
      aria-hidden="true"
      alt="glady-logo"
      priority="true"
    />
    <div class="error-message">{{ errorMessage }}</div>
  </div>
}
