import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { ONEP_ICON_SVG_PATH } from '@onep/ng-uikit-lib/icon';

import { routes } from './app.routes';
import { graphqlProvider } from './graphql.provider';
import { environment } from '../environments/environment';
import { provideNgxStripe } from 'ngx-stripe';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { provideLottieOptions } from 'ngx-lottie';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { TooltipModule } from '@onep/ng-uikit-lib/ui/tooltip';
import { GLADY_TRACKING_CONFIGURATION, TrackingConfiguration } from '@onep/front-common-lib/tracking';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(),
    graphqlProvider,
    { provide: ONEP_ICON_SVG_PATH, useValue: '/svg/wedooicons.svg' },
    provideAnimations(),
    provideNgxStripe(environment.payment.stripe.publicKey),
    provideTransloco({
      config: {
        availableLangs: ['fr'],
        defaultLang: 'fr',
        fallbackLang: 'fr',
        reRenderOnLangChange: false,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        fr: 'fr-FR',
      },
      localeToCurrencyMapping: {
        'fr-FR': 'EUR',
      },
      defaultLocale: 'fr-FR',
    }),
    provideTranslocoMessageformat({
      locales: 'fr-FR',
    }),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    importProvidersFrom(TooltipModule.forRoot()),
    {
      provide: GLADY_TRACKING_CONFIGURATION,
      useValue: {
        environment: environment.name,
        site: 'payment page',
      },
    },
  ],
};
