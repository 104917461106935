<div class="card" *transloco="let t; read: 'order.summary'">
  @if (billingOrderGifts && billingOrderGifts!.length > 0) {
    <div class="flex-row mb-4">
      <div class="fw-500 grey-light-black">
        {{ t('labels.gift') }}
      </div>
      <div class="bold grey-light-black ml-auto">
        {{ getAmount(billingOrderGifts) | translocoCurrency }}
      </div>
    </div>
  }
  @if (billingOrderCultures && billingOrderCultures!.length > 0) {
    <div class="flex-row mb-4">
      <div class="fw-500 grey-light-black">
        {{ t('labels.culture') }}
      </div>
      <div class="bold grey-light-black ml-auto">
        {{ getAmount(billingOrderCultures) | translocoCurrency }}
      </div>
    </div>
  }
  @if (billingOrderOptions && billingOrderOptions!.length > 0) {
    <div class="flex-row mb-4">
      <div class="fw-500 grey-light-black">
        {{ t('labels.options') }}
      </div>
      <div class="bold grey-light-black ml-auto">
        {{ getAmount(billingOrderOptions) | translocoCurrency }}
      </div>
    </div>
  }

  @if (countOrderItems() > 0) {
    <div class="flex-row items-center">
      <div class="mx-auto mt-8">
        <app-order-details-open-button [order]="order()"></app-order-details-open-button>
      </div>
    </div>
  }
</div>
