<div *transloco="let t; read: 'order.summary'">
  <div class="content">
    <div class="mb-24">
      <app-order-summary-card [order]="order()"></app-order-summary-card>
      @if (order().enablePromoCode) {
        <app-promo-code-card
          [token]="token()"
          [order]="order()"
          (promoCodeUpdated)="onPromoCodeUpdated()"
        ></app-promo-code-card>
      }
    </div>
    @if (order().amount.excludingTaxDiscountAmount) {
      <div class="flex-row items-center mb-8">
        <div class="onep-text-body-1 bold grey-light-black">
          {{ t('amount.promoCode') }}
        </div>

        <div class="onep-text-body-3 bold grey-light-black ml-auto">
          - {{ order().amount.excludingTaxDiscountAmount! | translocoCurrency }}
        </div>
      </div>
    }
    <div class="flex-row items-center mb-8">
      <div class="onep-text-body-1 bold grey-light-black">
        {{ t('amount.excludingTax') }}
      </div>
      <div class="onep-text-body-3 bold grey-light-black ml-auto">
        {{ getGrossAmount() | translocoCurrency }}
      </div>
    </div>
    <div class="flex-row items-center mb-8">
      <div class="onep-text-body-1 bold grey-light-black">
        {{ t('amount.tax') }}
      </div>
      <svg
        [onepIcon]="'question_circle'"
        [onepTooltip]="t('amount.taxTooltip')"
        onepTooltipPreferredPosition="right"
        class="tooltip-icon"
      ></svg>
      <div class="onep-text-body-3 bold grey-light-black ml-auto">
        {{ getTaxAmount() | translocoCurrency }}
      </div>
    </div>
  </div>
  <app-order-total-card [order]="order()"></app-order-total-card>
</div>
