import { Component, input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { injectStripe, StripeCardComponent, StripeElementsDirective, StripePaymentElementComponent } from 'ngx-stripe';
import { PaymentIntentResult, StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';
import { StripeService } from '../../../services/stripe.service';
import { SharedModule } from '../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from '@onep/ng-uikit-lib/ui/spinner';
import { WindowService } from '@onep/front-common-lib/window';
import { ModalService, ModalType } from '@onep/ng-uikit-lib/ui/modal';
import { PaymentProcessModalComponent } from '../../modals/payment-process-modal/payment-process-modal.component';
import { OverlayService } from '@onep/ng-uikit-lib/overlay';
import { PaymentProcessStatus } from '../../../models/enums/payment-process-status';
import { PaymentPageStatus } from '../../../models/enums/payment-page-status';
import { PaymentService } from '../../../services/payment.service';

@Component({
  selector: 'app-stripe-credit-card',
  standalone: true,
  imports: [
    SharedModule,
    StripeCardComponent,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    SpinnerModule,
  ],
  providers: [ModalService, OverlayService],
  templateUrl: './stripe-credit-card.component.html',
  styleUrl: './stripe-credit-card.component.scss',
})
export class StripeCreditCardComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  private readonly processPaymentSubject = new BehaviorSubject<PaymentProcessStatus>(PaymentProcessStatus.PROCESSING);

  token = input.required<string>();

  stripe = injectStripe(environment.payment.stripe.publicKey);

  elementsOptions: StripeElementsOptions = {
    appearance: {
      theme: 'stripe',
    },
    clientSecret: '',
  };

  paymentElementOptions: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        address: {
          country: 'never',
        },
      },
    },
  };

  constructor(
    private readonly stripeService: StripeService,
    private readonly paymentService: PaymentService,
    private readonly windowService: WindowService,
    private readonly modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.createPaymentIntent().subscribe((clientSecret) => (this.elementsOptions.clientSecret = clientSecret));
  }

  createPaymentIntent(): Observable<string> {
    return this.stripeService.getPaymentIntentSecret(this.token());
  }

  onPaymentClick(successUrl: string): void {
    this.paymentService.updateCurrentPaymentStatus(PaymentPageStatus.SUCCESS);
    this.processPaymentSubject.next(PaymentProcessStatus.PROCESSING);
    this.openPaymentProcessModal();
    this.stripe
      .confirmPayment({
        elements: this.paymentElement?.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: 'FR',
              },
            },
          },
          return_url: '',
        },
        redirect: 'if_required',
      })
      .subscribe((result) => {
        this.onResult(result, successUrl);
      });
  }

  private onResult(result: PaymentIntentResult, redirectUrl: string): void {
    if (result.error) {
      this.processPaymentSubject.next(PaymentProcessStatus.ERROR);
    } else if (result.paymentIntent.status === 'succeeded') {
      this.paymentService.updateCurrentPaymentStatus(PaymentPageStatus.SUCCESS);
      this.processPaymentSubject.next(PaymentProcessStatus.SUCCESS);
      of(null)
        .pipe(delay(1500))
        .subscribe(() => this.windowService.redirect(redirectUrl));
    }
  }

  private openPaymentProcessModal(): void {
    this.modalService.show({
      type: ModalType.LoadingModal,
      component: PaymentProcessModalComponent,
      backDropClose: false,
      input: {
        status$: this.processPaymentSubject.asObservable(),
        isAsync$: false,
      },
    });
  }
}
