/**
 * camelCase regex
 */
const regex = /[^a-zA-Z0-9]+(.)/g;

/**
 * Format to camelCase a snake_case or kebab-case string value.
 *
 * @param value value to adapt in camel case
 */
export function camelCase(value: string): string {
  /* Source : https://stackoverflow.com/a/52551910 */
  return value.toLowerCase().replace(regex, (_, chr) => chr.toUpperCase());
}
