import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  confirmationMessage: () => string;
}

/**
 * Guard to prevent user from leaving page since a payment method has been selected.
 * See https://stackoverflow.com/a/41187919
 */
export const CanLeaveGuard: CanDeactivateFn<ComponentCanDeactivate> = (
  component: ComponentCanDeactivate,
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
): boolean => (component.canDeactivate() ? true : confirm(component.confirmationMessage()));
