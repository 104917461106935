@if (order && order.items) {
  <div onepLayout *transloco="let t; read: 'order.summary.details'">
    <div onepColumn [mobile]="0" [tablet]="1" [desktop]="1"></div>
    <div onepColumn [mobile]="2" [tablet]="6" [desktop]="8">
      <onep-header-maxi-modal (closeModal)="closeModal()">
        <div class="onep-text-heading-1">{{ t('title') }}</div>
      </onep-header-maxi-modal>
      <onep-maxi-modal-container>
        <onep-distribution-summary>
          @if (billingOrderGifts && billingOrderGifts.length > 0) {
            <onep-distribution-summary-section>
              <h4 class="mb-16">{{ t('giftTitle') }}</h4>
              @for (item of billingOrderGifts; track $index) {
                <onep-distribution-summary-item
                  title="{{ getCheckType(item.checkType) + item.label }}"
                  description="{{ item.description }}"
                  amount="{{ item.totalAmount | translocoCurrency }}"
                  [freeAmount]="hasDiscount(item)"
                  [freeAmountDescription]="getDiscountedLabel(item)"
                >
                </onep-distribution-summary-item>
              }
            </onep-distribution-summary-section>
          }
          @if (billingOrderCultures && billingOrderCultures.length > 0) {
            <onep-distribution-summary-section>
              <h4 class="mb-16">{{ t('cultureTitle') }}</h4>
              @for (item of billingOrderCultures; track $index) {
                <onep-distribution-summary-item
                  title="{{ item.label }}"
                  description="{{ item.description }}"
                  amount="{{ item.totalAmount | translocoCurrency }}"
                  [freeAmount]="hasDiscount(item)"
                  [freeAmountDescription]="getDiscountedLabel(item)"
                >
                </onep-distribution-summary-item>
              }
            </onep-distribution-summary-section>
          }
          @if (billingOrderOptions && billingOrderOptions.length > 0) {
            <onep-distribution-summary-section>
              <h4 class="mb-16">{{ t('optionsTitle') }}</h4>
              @for (item of billingOrderOptions; track $index) {
                <onep-distribution-summary-item
                  title="{{ item.label }}"
                  description="{{ item.description }}"
                  amount="{{ item.totalAmount | translocoCurrency }}"
                  [freeAmount]="hasDiscount(item)"
                  [freeAmountDescription]="getDiscountedLabel(item)"
                >
                </onep-distribution-summary-item>
              }
            </onep-distribution-summary-section>
          }
          <onep-distribution-summary-section>
            <onep-distribution-summary-item
              title="{{ t('excludingTax') }}"
              amount="{{ getDueAmount() | translocoCurrency }}"
            >
            </onep-distribution-summary-item>
          </onep-distribution-summary-section>
        </onep-distribution-summary>
        <div class="modal-footer">
          <button
            onepButton
            [onepButtonType]="'primary'"
            [loading]="false"
            [size]="'normal'"
            (click)="closeModal()"
          >
            {{ t('backToPayment') }}
          </button>
        </div>
      </onep-maxi-modal-container>
    </div>
    <div onepColumn [mobile]="0" [tablet]="1" [desktop]="1"></div>
  </div>
}
